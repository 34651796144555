// eslint-disable-next-line import/no-cycle
import { HttpClientWrapper, DefaultErrorHandler, SuccessApiOperationResult, EmptyApiOperationResult, } from '@/service/api/client';
import { ns } from '@/store';
import { ADMIN_STATE_GETTERS, ADMIN_STATE_MUTATIONS, AdminStore, } from '@/store/admin';
import { bindAbilities } from '@/helpers/auth';
import { CookieEnum, } from '@vini-wine/core-enums';
import { BaseApiService } from '@/service/api/base/baseApiService';
import axios from 'axios';
import Vue from 'vue';
const ADMIN_ENDPOINT = `${process.env.VUE_APP_API_URL}/api/admin-api/v1`;
const adminApi = (store) => HttpClientWrapper.defaultInstance(store, { baseUrl: ADMIN_ENDPOINT });
// eslint-disable-next-line import/prefer-default-export
export class AdminApiService extends BaseApiService {
    // eslint-disable-next-line no-useless-constructor,no-unused-vars,no-empty-function
    constructor(store) {
        super();
        this.store = store;
    }
    async getVintageProductOffers(orgUuid, vintageProductUuid, pageQuery, queryParams, filterQueryParams) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/vintage-products/${vintageProductUuid}/offers`, { params: { ...pageQuery, ...queryParams, ...filterQueryParams } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUsers(query, queryParams) {
        const functionName = 'getUsers';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = adminApi(this.store).client();
            const params = { ...query, ...queryParams };
            const response = await httpClient.get('/users', {
                params,
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUserDetails(uuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/users/${uuid}/details`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getRoles() {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/roles');
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateUserStatus(uuid, statusId) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/users/${uuid}/details`, {
                data: {
                    status: {
                        id: statusId,
                    },
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async changeUserRoles(uuid, forceLogin, roles) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/users/${uuid}/roles`, {
                data: {
                    forceLogin,
                    roles,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUsers(uuid, query, queryParams) {
        const functionName = 'getOrganisationUsers';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = adminApi(this.store).client();
            const params = { ...query, ...queryParams };
            const response = await httpClient.get(`/organisations/${uuid}/users`, {
                params,
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUserDetails(orgUuid, userUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/users/${userUuid}/details`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUserInvitations(uuid, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${uuid}/user-invitations`, { params: { ...query } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async inviteUserToOrganisation(uuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`organisations/${uuid}/user-invitations`, data);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async changeStatusOfUserInvitation(uuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`organisations/${uuid}/user-invitations`, data);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationUserStatus(orgUuid, userUuid, statusId) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/users/${userUuid}/details`, {
                data: {
                    status: {
                        id: statusId,
                    },
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async changeOrganisationUserRoles(orgUuid, userUuid, roles) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/users/${userUuid}/roles`, {
                data: {
                    roles,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationRoles(uuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${uuid}/roles`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async registerOrganisation(organisation) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post('/user/register-organisation', {
                data: {
                    organisation,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationSuppliers(orgUuid, query, queryParams) {
        const functionName = 'getOrganisationSuppliers';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = adminApi(this.store).client();
            const params = { ...query, ...queryParams };
            const response = await httpClient.get(`organisations/${orgUuid}/suppliers`, {
                params,
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async createOrganisationSupplier(orgUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`organisations/${orgUuid}/suppliers`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationSupplier(orgUuid, supplierUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${orgUuid}/suppliers/${supplierUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationSupplier(orgUuid, supplierUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`organisations/${orgUuid}/suppliers/${supplierUuid}`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationCustomers(orgUuid, query, queryParams) {
        const functionName = 'getOrganisationCustomers';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = adminApi(this.store).client();
            const params = { ...query, ...queryParams };
            const response = await httpClient.get(`organisations/${orgUuid}/customers`, {
                params,
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async createOrganisationCustomer(orgUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`organisations/${orgUuid}/customers`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationCustomer(orgUuid, customerUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${orgUuid}/customers/${customerUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationCustomer(orgUuid, customerUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`organisations/${orgUuid}/customers/${customerUuid}`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getNotificationSettings(orgUuid, userUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${orgUuid}/users/${userUuid}/notifications-settings`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateNotificationSetting(orgUuid, userUuid, notificationType, notificationChannel, isActive) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`organisations/${orgUuid}/users/${userUuid}/notifications-settings/type/${notificationType}/channel/${notificationChannel}`, {
                isActive,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUserNotifications(query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('user/notifications', {
                params: { ...query },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async markNotificationAsRead(uuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`user/notifications/${uuid}/mark-as-read`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async markAllNotificationsAsRead() {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put('user/notifications/mark-as-read');
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async markNotificationsAsPreRead() {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put('user/notifications/mark-as-pre-read');
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateAccount(form) {
        try {
            const httpClient = adminApi(this.store).client();
            await httpClient.put('/user/account', form);
            this.store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.ACCOUNT_UPDATED), form);
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationAvatar(orgUuid, avatar) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/settings/details/avatar`, {
                avatar,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async deleteOrganisationAvatar(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.delete(`/organisations/${orgUuid}/settings/details/avatar`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateUserAvatar(avatar) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put('/user/avatar', {
                avatar,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async deleteUserAvatar() {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.delete('/user/avatar');
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getMyUserAccountDetails() {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/user/account');
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async resendActivationLink() {
        try {
            const httpClient = adminApi(this.store).client();
            await httpClient.post('/user/mail/verification');
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updatePassword(oldPassword, newPassword, newPasswordConfirm) {
        try {
            const httpClient = adminApi(this.store).client();
            await httpClient.put('/user/password', {
                current_password: oldPassword,
                password: newPassword,
                password_confirmation: newPasswordConfirm,
            });
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async importResolvedFileHeaderValues(orgUuid, supplierUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/suppliers/${supplierUuid}/import-resolved-file-header-value`, data);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async logout() {
        try {
            const httpClient = adminApi(this.store).client();
            // Obtain CSRF token just in case that this is not valid anymore
            await httpClient.get('/csrf-cookie');
            await httpClient.post('/user/logout');
            this.store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.USER_LOGGED_OUT));
            // remove the selected organisation cookie
            Vue.$cookies.remove(CookieEnum.SELECTED_ORGANISATION);
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async login(email, password, remember) {
        try {
            const httpClient = adminApi(this.store).client();
            // Obtain CSRF token
            await httpClient.get('/csrf-cookie');
            const loginResponse = (await httpClient.post('/user/login', {
                email,
                password,
                remember,
            })).data;
            const { data } = new SuccessApiOperationResult().withData(loginResponse.data);
            await this.store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.USER_LOGGED_IN), { loggedIn: true, ...data.userAccount });
            await this.store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.ADD_CURRENCY_RATES), { ...data.currencyRates });
            await bindAbilities(this.store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.USER)]
                .abilities);
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async register(data) {
        try {
            const httpClient = adminApi(this.store).client();
            // Obtain CSRF token
            await httpClient.get('/csrf-cookie');
            const registerResponse = (await httpClient.post('/user/register', data))
                .data;
            const account = new SuccessApiOperationResult().withData(registerResponse.data.userAccount).data;
            await this.store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.USER_LOGGED_IN), { loggedIn: true, ...account });
            await bindAbilities(this.store.getters[ns(AdminStore.NS, ADMIN_STATE_GETTERS.USER)]
                .abilities);
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async requestPasswordReset(email) {
        try {
            const httpClient = adminApi(this.store).client();
            // Obtain CSRF token
            await httpClient.get('/csrf-cookie');
            await httpClient.post('/user/forgot-password', { email });
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async resetPassword(email, password, passwordConfirmation, token) {
        try {
            const httpClient = adminApi(this.store).client();
            // Obtain CSRF token
            await httpClient.get('/csrf-cookie');
            await httpClient.post('/user/reset-password', {
                email,
                password,
                password_confirmation: passwordConfirmation,
                token,
            });
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUserInvitation(token) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/user/organisation-user-invitation/${token}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async acceptOrDeclineInvitationFromUser(data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put('/user/organisation-user-invitation', data);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getSupplierWineTags(organisationId, supplierId, items) {
        try {
            const response = await adminApi(this.store)
                .client()
                .post(`/organisations/${organisationId}/suppliers/${supplierId}/wine-tags`, { items });
            return Promise.resolve(response.data.data);
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getSupplierImportFileHeaders(organisationId, supplierId, items) {
        try {
            const response = await adminApi(this.store)
                .client()
                .post(`/organisations/${organisationId}/suppliers/${supplierId}/import-file-headers`, { items });
            return Promise.resolve(response.data.data);
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOfferedVintageProducts(orgUuid, query, queryParams, filterQueryParams) {
        const functionName = 'getOfferedVintageProducts';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/offered-vintage-products`, {
                params: { ...query, ...queryParams, ...filterQueryParams },
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOfferAlerts(orgUuid, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/offer-alerts`, {
                params: {
                    ...query,
                    ...{
                        'orderBy[id]': 'desc',
                    },
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async deleteOfferAlert(orgUuid, offerAlertUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.delete(`/organisations/${orgUuid}/offer-alerts/${offerAlertUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async createOfferAlert(orgUuid, data, meta) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/offer-alerts`, {
                data,
                meta,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOfferAlert(orgUuid, offerAlertUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/offer-alerts/${offerAlertUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisations(query, queryParams) {
        const functionName = 'getOrganisations';
        try {
            this.cancelRequest(functionName);
            this.cancelTokens[functionName] = axios.CancelToken.source();
            const httpClient = adminApi(this.store).client();
            const params = { ...query, ...queryParams };
            const response = await httpClient.get('/administration/organisations', {
                params,
                cancelToken: this.cancelTokens[functionName].token,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationDetails(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/organisations/${orgUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getAdministrationOrganisationUsers(orgUuid, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/organisations/${orgUuid}/users`, { params: { ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getAdministrationOrganisationSuppliers(orgUuid, pageQuery, search) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/organisations/${orgUuid}/suppliers`, { params: { ...pageQuery, search } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getAdministrationOrganisationSuppliedSuppliers(orgUuid, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/organisations/${orgUuid}/supplied-suppliers`, { params: { ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getMarketMargins(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/margins/market-margins`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationMargins(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/margins/organisation-margins`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationDefaultMargin(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/margins/default-margin`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationDefaultMargin(orgUuid, margin) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/margins/default-margin`, {
                data: {
                    margin,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateMarketMargin(orgUuid, countryCode, margin) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/margins/market-margins/${countryCode}`, {
                data: {
                    margin,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationMargin(orgUuid, orgMarginUuid, margin) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/margins/organisation-margins/${orgMarginUuid}`, {
                data: {
                    margin,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async deleteOrganisationMargin(orgUuid, orgMarginUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.delete(`/organisations/${orgUuid}/margins/organisation-margins/${orgMarginUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async deleteMarketMargin(orgUuid, countryCode) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.delete(`/organisations/${orgUuid}/margins/market-margins/${countryCode}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async passwordStatusConfirm(password) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post('/user/password/status', {
                password,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationSettingsSalesRequestsSupplierAutomation(orgUuid, automation) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/settings/sales/requests/supplier-automation?automation=${automation}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationSettingsSalesRequestsSupplierAutomation(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/settings/sales/requests/supplier-automation`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationNumUploads(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/statistics/num-uploads?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationNumOffers(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/statistics/num-offers?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationNumOfferRequests(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/statistics/num-offer-requests?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationNumOfferAlerts(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/statistics/num-offer-alerts?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationSupplierUploadStatistics(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/upload-statistics/suppliers?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOfferRequestsStatistics(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offer-requests/statistics?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOfferRequestsTimeline(orgUuid, visibleDateTime, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offer-requests/timeline?visibleDateTime=${visibleDateTime}`, {
                params: {
                    ...pageQuery,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOfferAlertsTimeline(orgUuid, visibleDateTime, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offer-alerts/timeline?visibleDateTime=${visibleDateTime}`, {
                params: {
                    ...pageQuery,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationUploadsTimeline(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/upload-statistics/uploads-timeline?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOffersPriceRanges(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offers/price-ranges?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOffersB2BMarketPriceRanges(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offers/b2b-market-price-ranges?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOffersWineTypes(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offers/wine-types?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOffersVivinoRatings(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offers/vivino-ratings?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationMapLocations(orgUuid, visibleDateTime) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/map/locations?visibleDateTime=${visibleDateTime}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOffersTopOffersVsB2BMarketPrice(orgUuid, visibleDateTime, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/general-dashboard/offers/top-offers-vs-b2b-market-price?visibleDateTime=${visibleDateTime}`, { params: { ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationDefaultTimezone(orgUuid, defaultTimezone) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/settings/details/default-timezone`, {
                defaultTimezone,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async makeOrganisationCustomerSupplier(orgUuid, customerUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/customers/${customerUuid}/make-supplier`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async makeOrganisationSupplierCustomer(orgUuid, supplierUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/suppliers/${supplierUuid}/make-customer`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationIntegrationExact(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/integrations/exact`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async createOrganisationIntegrationExact(orgUuid, data, meta) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/integrations/exact`, {
                data,
                meta,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async activateOrganisationIntegrationExact(orgUuid, exactUuid, authorisationCode) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`/organisations/${orgUuid}/integrations/exact/${exactUuid}/activate`, {
                data: {
                    authorisationCode,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationPreferredCurrency(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${orgUuid}/currencies/preferred-currency`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationPreferredCurrency(orgUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`organisations/${orgUuid}/currencies/preferred-currency`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUploads(pageQuery, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/administration/uploads', {
                params: { ...pageQuery, ...query },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getAdministrationUploadPayload(uploadUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/uploads/${uploadUuid}/payload`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async administrationUpdateUploadStatus(uploadUuid, statusId) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/administration/uploads/${uploadUuid}/status`, {
                data: {
                    status: {
                        id: statusId,
                    },
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateUpload(uploadUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/administration/uploads/${uploadUuid}`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async synchroniseCustomerWithExact(orgUuid, customerUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/customers/${customerUuid}/exact/sync`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async synchroniseSupplierWithExact(orgUuid, supplierUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/suppliers/${supplierUuid}/exact/sync`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async synchroniseVintageProductWithExact(orgUuid, vintageProductUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/vintage-products/${vintageProductUuid}/exact/sync`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationPricingPlans(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/pricing-plans`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async editOrganisationPricingPlan(orgUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/pricing-plan`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationCustomerContactPoints(orgUuid, customerUuid, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/customers/${customerUuid}/contact-points`, { params: { ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationSupplierContactPoints(orgUuid, supplierUuid, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/suppliers/${supplierUuid}/contact-points`, { params: { ...pageQuery } });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationIntegrationExactUser(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/integrations/exact/user`);
            // TODO: Transformer
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUserOrganisationsByRequestUser() {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/user/userOrganisations');
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async setUserOrganisationDefaultOrganisation(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            await httpClient.put(`/user/userOrganisations/${orgUuid}/is-user-default-organisation`);
            return Promise.resolve(new EmptyApiOperationResult());
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationPricingPlan(orgUuid, cacheMiss) {
        try {
            const httpClient = adminApi(this.store).client();
            let url = `/organisations/${orgUuid}/pricing-plan`;
            // Check if cacheMiss is provided, if so, append it to the URL
            if (typeof cacheMiss === 'boolean') {
                url += `?cacheMiss=${cacheMiss}`;
            }
            const response = await httpClient.get(url);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async switchUserOrganisationByRequestUserAndOrganisationUuid(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/user/userOrganisations/${orgUuid}/switch`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getInboundEmails(pageQuery, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/administration/inbound-email', {
                params: { ...pageQuery, ...query },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getInboundEmail(inboundEmailId) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/inbound-email/${inboundEmailId}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getInboundEmailParsedItems(inboundEmailParsedUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/inbound-email-parsed/${inboundEmailParsedUuid}/items`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async createUpload(uploadUuid, orgUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`/administration/uploads/${uploadUuid}/organisations/${orgUuid}`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationEmailParserUploadType(orgUuid, uploadType) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/email-parser/${uploadType}/email`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async editOrganisationSupplierStatus(orgUuid, supplierUuid, id) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/suppliers/${supplierUuid}/status`, {
                data: {
                    status: {
                        id,
                    },
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async editOrganisationCustomerStatus(orgUuid, customerUuid, id) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/customers/${customerUuid}/status`, {
                data: {
                    status: {
                        id,
                    },
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOrdersSales(orgUuid, pageQuery, queryParams) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${orgUuid}/orders/sales`, {
                params: { ...pageQuery, ...queryParams },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOrdersPurchase(orgUuid, pageQuery, queryParams) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`organisations/${orgUuid}/orders/purchase`, {
                params: { ...pageQuery, ...queryParams },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationInboundEmailsParsed(orgUuid, pageQuery, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/inbound-email-parsed`, {
                params: { ...pageQuery, ...query },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationInboundEmailParsed(orgUuid, inboundEmailUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/inbound-email-parsed/${inboundEmailUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationInboundEmailParsedItems(orgUuid, inboundEmailUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/inbound-email-parsed/${inboundEmailUuid}/items`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async synchroniseOrderWithExact(orgUuid, orderUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/orders/${orderUuid}/exact/sync`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationSalesOrPurchaseOrder(orgUuid, orderUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/orders/${orderUuid}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationDefaultShippingPostalAddress(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/default-shipping-postal-address`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationDefaultShippingPostalAddress(orgUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/default-shipping-postal-address`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationBillingPostalAddress(orgUuid) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/billing-postal-address`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateOrganisationBillingPostalAddress(orgUuid, data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/organisations/${orgUuid}/billing-postal-address`, {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUserClaimOrganisationRequests(pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/user/claim-organisation-requests', {
                params: {
                    ...pageQuery,
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationByVatTaxValueAndByVatTaxCountryCode(vatTaxValue, vatTaxCountryCode) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisation/vat-tax-value/${vatTaxValue}/vat-tax-country-code/${vatTaxCountryCode}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async createUserClaimOrganisationRequest(vatTaxCountryCode, vatTaxValue) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post(`/user/claim-organisation-requests?vatTaxCountryCode=${vatTaxCountryCode}&vatTaxValue=${vatTaxValue}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async uploadB2BMarketPrices(data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post('/administration/uploads/b2b-market-prices', {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationOrganisation(orgUuid, orgUuidForDetails) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/organisations/${orgUuidForDetails}`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async uploadCriticScores(data) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.post('/administration/uploads/critic-scores', {
                data,
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getOrganisationIntegrationExactLogs(orgUuid, integrationExactUuid, pageQuery) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/organisations/${orgUuid}/integrations/exact/${integrationExactUuid}/logs?orderBy[createdAt]=desc`, {
                params: { ...pageQuery },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getB2bMarketPriceUploads(pageQuery, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/administration/uploads/b2b-market-prices', {
                params: { ...pageQuery, ...query },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getVintageScoreUploads(pageQuery, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/administration/uploads/critic-scores', {
                params: { ...pageQuery, ...query },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getVintageImagesByImageType(vintageUuid, imageType) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get(`/administration/vintages/${vintageUuid}/${imageType}/images`);
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async getUserOrganisationClaimRequests(pageQuery, query) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.get('/administration/user-organisation-claim-requests', {
                params: { ...pageQuery, ...query },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async updateUserOrganisationClaimRequestStatus(userOrganisationClaimRequestUuid, statusId) {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put(`/administration/user-organisation-claim-requests/${userOrganisationClaimRequestUuid}/status`, {
                data: {
                    status: {
                        id: statusId,
                    },
                },
            });
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
    async skipTour() {
        try {
            const httpClient = adminApi(this.store).client();
            const response = await httpClient.put('/user/skip-tour');
            return Promise.resolve(new SuccessApiOperationResult().withData(response.data.data));
        }
        catch (e) {
            return DefaultErrorHandler.handleError(e);
        }
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject } from 'vue-property-decorator';
import { BModal, BButton } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import Toast from '@/mixin/toast';
import { ns } from '@/store';
import { ADMIN_STATE_MUTATIONS, AdminStore } from '@/store/admin';
let OnboardingTourModal = class OnboardingTourModal extends mixins(Toast) {
    async showModal() {
        this.$root.$emit('bv::show::modal', 'onboarding-tour-modal', '#btnShow');
    }
    async skipTour() {
        const response = await this.adminApi.skipTour();
        if (response.isError()) {
            return this.errorToast(response.message);
        }
        this.$store.commit(ns(AdminStore.NS, ADMIN_STATE_MUTATIONS.SET_TOUR_SKIPPED_AT), response.data.tourSkippedAt);
        return true;
    }
};
__decorate([
    Inject('adminApi')
], OnboardingTourModal.prototype, "adminApi", void 0);
OnboardingTourModal = __decorate([
    Component({
        components: { BModal, BButton },
    })
], OnboardingTourModal);
export default OnboardingTourModal;
